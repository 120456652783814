<template>
	<section
		:id="slug"
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
			'standalone': standalone,
		}"
		class="page-press"
	>
		<header class="page-header">
			<h1 class="page-title" v-html="title" />
		</header>

		<div
			:style="{
				'grid-template-columns': new Array(columnsCount).fill('1fr').join(' '),
				'--columns': columnsCount,
			}"
			class="columns-wrapper"
		>
			<template
				v-for="(quoteData, index) in page.quotes"
			>
				<article
					:key="`${index}-member`"
					:style="{
						'--cell-width': 1,
					}"

					class="quote-block"
				>
					<svg class="quote-sign" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
						<use xlink:href="#quote-sign" />
					</svg>
					<blockquote class="quote" v-html="quoteData.quote" />
					<footer class="quote-footer">
						<span class="author">{{quoteData.top_signature}}</span>
						<span class="author-details">{{quoteData.lower_signature}}</span>
					</footer>
				</article>

				<span
					v-if="(mobileMode || quoteData.separator) && index < page.quotes.length - 1"
					:key="`${index}-separator`"
					class="separator"
				/>
			</template>
		</div>

		<svg v-show="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 23 17">
			<defs>
				<symbol id="quote-sign" viewBox="0 0 23 17">
					<path d="M14.23522,16.10153c-2.39848,-0.6511 -3.76131,-3.10787 -3.04395,-5.48734c0.2185,-0.72476 0.60479,-1.35875 1.10415,-1.87163l6.02053,-8.74256l2.73854,1.67716l-4.03675,5.86188c2.28315,0.72429 3.55799,3.11532 2.85925,5.43301c-0.71737,2.37947 -3.24327,3.7806 -5.64177,3.12949z" fill="#000000"></path>
					<path d="M3.23522,16.10153c-2.39848,-0.6511 -3.76131,-3.10787 -3.04395,-5.48734c0.2185,-0.72476 0.60479,-1.35875 1.10415,-1.87163l6.02053,-8.74256l2.73854,1.67716l-4.03675,5.86188c2.28315,0.72429 3.55799,3.11532 2.85925,5.43301c-0.71737,2.37947 -3.24327,3.7806 -5.64177,3.12949z" fill="#000000"></path>
				</symbol>
			</defs>
		</svg>

	</section>
</template>

<script>
import { resolveRelativeStaticURL } from "@/utilites.js";
import subpageMixin from './subpage-mixin';

export default {
	mixins: [
		// adds `pageData` prop and `page` computed value
		subpageMixin,
	],

	props: {
		// `pageData` via subpage mixin
	},

	computed: {
		// [page, slug] via subpage mixin

		columnsCount() {
			// based on viewport width
			// let pagePadding = this.mobileMode ? 25 : 40;
			// let gridGap = 60;
			// let reference_col_width = 400;
			// return Math.floor((this.windowWidth - 2 * pagePadding + gridGap) / (reference_col_width + gridGap));

			return this.mobileMode ? 1 : 3;
		},
	},

	methods: {
		getImageUrl(image) {
			return resolveRelativeStaticURL(image.meta.download_url);
		}
	}
};
</script>

<style lang="sass" scoped>
.page-press
	@extend %page !optional

	&.mobile
		@extend %page-mobile

		&.standalone
			@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop

		&.standalone
			@extend %page-desktop-standalone


.page-header
	@extend %page-header !optional

	.mobile &
		@extend %page-header-mobile

	.mobile.standalone &
		@extend %page-header-mobile-standalone

	.desktop &
		@extend %page-header-desktop

	.desktop.standalone &
		@extend %page-header-desktop-standalone


.page-title
	@extend %page-title

	.mobile &
		@extend %page-title-mobile

	.desktop &
		@extend %page-title-desktop



.columns-wrapper
	--columns: 3
	display: grid
	grid-template-columns: repeat(var(--columns, 3), 1fr)

	// grid-column-gap: rslv($pad__x)
	.desktop &
		grid-column-gap: rslv($pad__x * 2)
		grid-row-gap: rslv($pad__x)

	.mobile &
		grid-column-gap: rslv($pad__x__mob * 2)
		grid-row-gap: rslv($pad__x__mob)

	.separator
		display: block
		grid-column-end: span var(--columns, 3)
		align-self: center
		border-bottom: 1px solid #dddddd

		.mobile &
			margin-bottom: 25px


	& > *
		--cell-width: 1
		grid-column-end: span var(--cell-width, 1)

		// .desktop &
		// 	max-width: calc(100vw - #{rslv($pad__x)} * 2)

		// 	&:not(:last-child)
		// 		margin: 0 rslv($p-std__column-gap)
		// 		margin-left: 0

		// .mobile &
		// 	max-width: calc(100vw - #{rslv($pad__x__mob)} * 2)

		// 	&:not(:last-child)
		// 		margin-bottom: rslv($p-std__column-gap__mob)

.quote-block
	display: flex
	flex-direction: column
	align-items: stretch
	justify-content: flex-start
	position: relative

	.mobile &
		padding: 0 0 0 25px
		margin-bottom: 25px

	.desktop &
		// margin-bottom: 60px
		padding: 0 0 0 40px

.quote-sign
	display: block
	position: absolute

	.mobile &
		width: 23px
		height: 17px
		left: 17px - 25px

	.desktop &
		width: 23px
		height: 17px
		top: 7px
		left: 38px - 40px

.quote-footer

	.mobile &
		margin-top: 1em * 25 / 14
		@extend %p-mobile

	.desktop &
		margin-top: 2em * 34 / 18
		@extend %small-regular-desktop

.author
	display: block
	font-weight: bold

.author-details
	display: block
	color: #6d7278


.quote
	margin: 0 0 auto

	.mobile &
		@extend %p-mobile

	.desktop &
		@extend %p-desktop

	& ::v-deep p
		// text-indent: 2em
		margin: 0
		padding: 0
		// white-space: pre-wrap // preserves all \s

		.mobile &
			&:not(:last-child)
				margin-bottom: rslv($fs__default-text__mob * $lh__default-text__mob)

		.desktop &
			&:not(:last-child)
				margin-bottom: rslv($fs__default-text * $lh__default-text)

	& ::v-deep nobr
		white-space: nowrap

	& ::v-deep .margined_links a
		margin-right: 1.5em

</style>
