var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-press",class:{
		'mobile': _vm.mobileMode,
		'desktop': !_vm.mobileMode,
		'standalone': _vm.standalone,
	},attrs:{"id":_vm.slug}},[_c('header',{staticClass:"page-header"},[_c('h1',{staticClass:"page-title",domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('div',{staticClass:"columns-wrapper",style:({
			'grid-template-columns': new Array(_vm.columnsCount).fill('1fr').join(' '),
			'--columns': _vm.columnsCount,
		})},[_vm._l((_vm.page.quotes),function(quoteData,index){return [_c('article',{key:(index + "-member"),staticClass:"quote-block",style:({
					'--cell-width': 1,
				})},[_c('svg',{staticClass:"quote-sign",attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('use',{attrs:{"xlink:href":"#quote-sign"}})]),_c('blockquote',{staticClass:"quote",domProps:{"innerHTML":_vm._s(quoteData.quote)}}),_c('footer',{staticClass:"quote-footer"},[_c('span',{staticClass:"author"},[_vm._v(_vm._s(quoteData.top_signature))]),_c('span',{staticClass:"author-details"},[_vm._v(_vm._s(quoteData.lower_signature))])])]),((_vm.mobileMode || quoteData.separator) && index < _vm.page.quotes.length - 1)?_c('span',{key:(index + "-separator"),staticClass:"separator"}):_vm._e()]})],2),_c('svg',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 23 17"}},[_c('defs',[_c('symbol',{attrs:{"id":"quote-sign","viewBox":"0 0 23 17"}},[_c('path',{attrs:{"d":"M14.23522,16.10153c-2.39848,-0.6511 -3.76131,-3.10787 -3.04395,-5.48734c0.2185,-0.72476 0.60479,-1.35875 1.10415,-1.87163l6.02053,-8.74256l2.73854,1.67716l-4.03675,5.86188c2.28315,0.72429 3.55799,3.11532 2.85925,5.43301c-0.71737,2.37947 -3.24327,3.7806 -5.64177,3.12949z","fill":"#000000"}}),_c('path',{attrs:{"d":"M3.23522,16.10153c-2.39848,-0.6511 -3.76131,-3.10787 -3.04395,-5.48734c0.2185,-0.72476 0.60479,-1.35875 1.10415,-1.87163l6.02053,-8.74256l2.73854,1.67716l-4.03675,5.86188c2.28315,0.72429 3.55799,3.11532 2.85925,5.43301c-0.71737,2.37947 -3.24327,3.7806 -5.64177,3.12949z","fill":"#000000"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }